import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import sites from "../constants/sites"

import styled from "styled-components"

const Translation = () => {
  const data = useStaticQuery(graphql`
    query GetLaImage {
      gameCenter: file(relativePath: { eq: "gamecenter.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Layout>
        <SEO title="Translation" />
        <StyledImage fluid={data.gameCenter.childImageSharp.fluid} />
        <p>
          I am looking to offer Japanese to English translation services. I
          began learning Japanese over a decade ago, and I lived in Japan for a
          year and 4 months as a student at Kansai Gaidai University. I passed
          the Japanese Language Proficiency Test at the N2 and N1 levels. I also
          have experience interning in Japan with Sumitomo Mitsui, a Japanese
          bank.
        </p>
        <h2>Translations</h2>
        <ul>
          <li>Game Center CX - Pokemon Red & Green Special</li>
          <li>Game Center CX - Mega Man 9</li>
          <li>Game Center CX - Castlevania (Proofreading)</li>
        </ul>
      </Layout>
    </Wrapper>
  )
}

const StyledImage = styled(Img)`
  border-radius: 10px;
  width: 450px;
  margin: auto;
  margin-bottom: 2.25rem;
`

const Wrapper = styled.div`
  /* padding: 1rem; */
  height: 100vh;
  /* background-color: dodgerblue; */
`

export default Translation
